import Swiper from 'swiper'
import { Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

export class ThumbSlider01 {
  constructor() {
    this.sliderArr = document.querySelectorAll('.js-thumb_slider01')
    if (this.sliderArr.length === 0) return

    this.sliderArr.forEach((e) => {
      const mainSlider = e.querySelector('.main')
      const thumbSlider = e.querySelector('.thumb')
      const buttonNext = e.querySelector('.js-slider_button.-next')
      const buttonPrev = e.querySelector('.js-slider_button.-prev')
      this.initSwiper(mainSlider, thumbSlider, buttonNext, buttonPrev)
    })
  }

  initSwiper(slider, thumb, next, prev) {
    const swiperThumb = new Swiper(thumb, {
      slidesPerView: 3,
      spaceBetween: 5,
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    })
    const swiperMain = new Swiper(slider, {
      modules: [Navigation, Thumbs],
      thumbs: {
        swiper: swiperThumb,
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
    })
  }
}
