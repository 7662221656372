import { Loader } from '@googlemaps/js-api-loader'

export class GoogleMap {
  constructor($el = null) {
    this.$el = document.querySelectorAll('.acf-map')

    this.loader = new Loader({
      apiKey: 'AIzaSyB9BstoUfOkrp1a_thyE9hj-SYvdHT-XFs',
      version: 'weekly',
    })

    this.map = null
    this.$el.forEach(($el) => {
      this.initMap($el)
    })
  }

  /** initMap */
  async initMap($el) {
    const $markers = $el.querySelectorAll('.marker')

    const mapOptions = {
      zoom: parseInt($el.dataset.zoom) || 16,
      mapTypeId: 'roadmap',
      styles: [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#444444',
            },
          ],
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.fill',
          stylers: [
            {
              saturation: '6',
            },
            {
              visibility: 'on',
            },
            {
              weight: '1.89',
            },
          ],
        },
        {
          featureType: 'landscape',
          stylers: [
            {
              color: '#f2f2f2',
            },
          ],
        },
        {
          featureType: 'poi.attraction',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.medical',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'poi.medical',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'poi.medical',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.medical',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'poi.park',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road',
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit.station.airport',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'transit.station.airport',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit.station.bus',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'transit.station.rail',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit.station.rail',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          stylers: [
            {
              color: '#c7e6f4',
            },
            {
              visibility: 'on',
            },
          ],
        },
      ],
    }

    await this.loader.load()

    this.map = new google.maps.Map($el, mapOptions)

    this.map.markers = []

    $markers.forEach(($marker) => {
      this.initMarker($marker, this.map)
    })

    this.centerMap(this.map)
  }

  /** initMarker */
  initMarker($marker, map) {
    const lat = parseFloat($marker.dataset.lat)
    const lng = parseFloat($marker.dataset.lng)
    const latLng = { lat, lng }

    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
      icon: {
        url: '/img/common/ico/ico-googlemap-marker01.svg',
        //  new google.maps.Size(36, 48),
        scaledSize: new google.maps.Size(18, 24),
      },
    })

    map.markers.push(marker)

    if ($marker.innerHTML) {
      const infowindow = new google.maps.InfoWindow({
        content: $marker.innerHTML,
      })

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker)
      })
    }
  }

  /** centerMap */
  centerMap(map) {
    const bounds = new google.maps.LatLngBounds()
    map.markers.forEach((marker) => {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      })
    })

    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter())
    } else {
      map.fitBounds(bounds)
    }
  }
}
