'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IndexVoiceSlider } from './lib/IndexVoiceSlider'
import { MegaMenu } from './lib/MegaMenu'
import { IndexKvSlider } from './lib/IndexKvSlider'
import { Accordion } from './lib/Accordion'
import { Loading } from './lib/Loading'
import { Lottie } from './lib/Lottie'
import { AnchorSidebar } from './lib/AnchorSidebar'
import { Tab } from './lib/Tab'
import { RegionGallery } from './lib/RegionGallery'
import { SchoolVoiceSlider } from './lib/SchoolVoiceSlider'
import { TextSplit } from './lib/TextSplit'
import { AccordionExpand } from './lib/AccordionExpand'
import { GoogleMap } from './lib/GoogleMap'
import { ThumbSlider01 } from './lib/ThumbSlider01'
import { Clipboard } from './lib/Clipboard'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new IndexVoiceSlider()
new MegaMenu()
new IndexKvSlider()
new Accordion()
new Loading()
new Lottie()
new AnchorSidebar()
new Tab()
new RegionGallery()
new SchoolVoiceSlider()
new TextSplit()
new AccordionExpand()
new GoogleMap()
new ThumbSlider01()
new Clipboard()
