import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'

export class IndexKvSlider {
  constructor() {
    this.slider = document.querySelector('.js-index_kv_slider')
    if (!this.slider) return

    let swiper

    this.initSlider = () => {
      swiper = new Swiper(this.slider, {
        modules: [Autoplay],
        slidesPerView: 0.8,
        spaceBetween: 5,
        allowTouchMove: false,
        loop: true,
        speed: 15000,
        autoplay: {
          delay: 0,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
        on: {
          init: function () {
            this.autoplay.start()
            // console.log('autoplay start')
          },
        },
      })
    }
  }
}
