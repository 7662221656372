import { gsap } from 'gsap'
import { Power3 } from 'gsap'

/**
 * ドロワーメニューの開閉機能
 */
export class Drawer {
  constructor() {
    this.hamburger = document.getElementById('js-hamburger')
    this.drawer = document.getElementById('js-drawer')
    if (this.hamburger === null || this.drawer === null) return

    this.body = document.body

    this.hamburger.addEventListener('click', () => {
      this.hamburger.querySelector('span').classList.toggle('hidden')
      this.hamburger.classList.toggle('is-act')
      if (this.hamburger.classList.contains('is-act')) {
        this.animation(this.drawer, 'open')
      } else {
        this.animation(this.drawer, 'close')
      }
    })

    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    const handleMedia = () => {
      if (mediaQuery.matches) {
        this.hamburger.classList.remove('is-act')
        this.animation(this.drawer, 'close')
      }
    }

    mediaQuery.addListener(handleMedia)

    window.addEventListener('resize', () => {
      handleMedia(mediaQuery)
    })
  }
  animation(el, type) {
    if (type == 'open') {
      this.body.classList.add('is-drawer-open')
      this.body.classList.add('is-fixed')
    } else {
      this.body.classList.remove('is-drawer-open')
      this.body.classList.remove('is-fixed')
    }
  }
}
