// import { IsTouchDevice } from './Ua'
// const isTouchDevice = IsTouchDevice()
export class MegaMenu {
  constructor() {
    const megaArr = document.querySelectorAll('.js-mega')
    if (!megaArr) return

    megaArr.forEach((mega) => {
      const megaTrg = mega.querySelector('.js-mega_trg')
      const megaChild = mega.querySelector('.js-mega_child')

      // ホバー
      megaTrg.addEventListener('mouseover', () => {
        mega.classList.add('is-act')
      })
      megaChild.addEventListener('mouseover', () => {
        mega.classList.add('is-act')
      })
      megaTrg.addEventListener('mouseout', () => {
        mega.classList.remove('is-act')
      })
      megaChild.addEventListener('mouseout', () => {
        mega.classList.remove('is-act')
      })

      // タッチ
      megaTrg.addEventListener('touchstart', () => {
        mega.classList.toggle('is-act')
      })
    })
  }
}
