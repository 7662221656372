export class Clipboard {
  constructor() {
    this.targets = document.querySelectorAll('.js-share-trg')

    this.targets.forEach((target) => {
      target.addEventListener('click', (event) => {
        copyToClipboard(event.currentTarget)
      })
    })

    const copyToClipboard = (elm) => {
      const text = elm.getAttribute('data-clipboard-text')
      const tempInput = document.createElement('input')
      tempInput.value = text
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      const copiedElm = elm.querySelector('.js-share-copied')
      if (!copiedElm) return
      copiedElm.classList.add('is-act')
      setTimeout(() => {
        copiedElm.classList.remove('is-act')
      }, 2000)
    }
  }
}
