import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'

export class RegionGallery {
  constructor() {
    this.slider = document.querySelector('.js-region_gallery')
    if (!this.slider) return

    let swiper

    this.initSwiper = () => {
      swiper = new Swiper(this.slider, {
        modules: [Autoplay],
        slidesPerView: 1.1,
        spaceBetween: 20,
        allowTouchMove: false,
        loop: true,
        speed: 15000,
        autoplay: {
          delay: 0,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 40,
          },
        },
      })
    }

    this.initSwiper()
  }
}
