import { gsap } from 'gsap'
import { IndexKvSlider } from './IndexKvSlider'

export class Loading {
  constructor() {
    const kv = document.getElementById('js-index_kv')
    const page = document.getElementById('page')

    if (!kv) {
      document.addEventListener('DOMContentLoaded', () => {
        gsap.to(page, 0.5, {
          opacity: 1,
        })
      })
      return
    }

    const loading = document.getElementById('js-loading')

    const cloud01 = loading.querySelector('.c-loading__cloud.-i01')
    const cloud02 = loading.querySelector('.c-loading__cloud.-i02')
    const plane = loading.querySelector('.c-loading__plane')
    const logo = loading.querySelector('.c-loading__logo')

    const body = document.body
    const kvSlider = new IndexKvSlider()

    const status = sessionStorage.getItem('status')
    // console.log(status)

    body.classList.add('is-load')

    // 2度目以降の処理
    if (status === 'visited') {
      loading.style.display = 'none'
      let tl2 = gsap.timeline()
      document.addEventListener('DOMContentLoaded', () => {
        gsap.to(page, 0.5, {
          opacity: 1,
        })
        tl2 = gsap.timeline()
        tl2.to(
          loading,
          {
            duration: 1.5,
            onStart: () => {
              body.classList.add('is-load-start')
              body.classList.add('is-load-end')
              body.classList.add('is-load-complete')
              kvSlider.initSlider()
            },
          },
          '+=0.5'
        )
      })
      window.addEventListener('load', () => {})
      return
    }
    // ここまで

    // 初回読み込み
    sessionStorage.setItem('status', 'visited')
    body.classList.add('is-fixed')
    gsap.to(page, 0.5, {
      opacity: 1,
    })

    let tl1 = gsap.timeline()

    // document.addEventListener('DOMContentLoaded', () => {

    // })

    window.addEventListener('load', () => {
      tl1.to(
        loading,
        {
          duration: 1,
          onStart: () => {
            body.classList.add('is-load-start')
          },
          onComplete: () => {},
        },
        '+=1'
      )
      tl1.to(cloud02, { scale: 1.1, opacity: 0, duration: 2 }, '<0.5')
      tl1.to(
        plane,
        {
          onStart: () => {
            plane.classList.add('is-act')
          },
        },
        '<'
      )
      tl1.to(cloud01, { scale: 1.1, opacity: 0, duration: 2 }, '<2')
      tl1.to(logo, { opacity: 1, duration: 1 }, '<1')
      tl1.to(
        loading,
        {
          opacity: 0,
          duration: 1,
          onStart: () => {
            body.classList.add('is-load-end')
            body.classList.remove('is-fixed')
            kvSlider.initSlider()
          },
        },
        '+=1'
      )

      tl1.to(
        loading,
        {
          duration: 1.5,
          onComplete: () => {
            loading.style.display = 'none'
            body.classList.add('is-load-complete')
          },
        },
        '+=1'
      )
      // tl1.from(CopyEn, { y: 10, opacity: 0, duration: 1 }, '<1')
      // tl1.from(CopyJp, { y: 10, opacity: 0, duration: 1 }, '<0.2')
    })
  }
}
