import lottie from 'lottie-web'
export class Lottie {
  constructor() {
    if (document.querySelector('.js-lottie') === null) return
    const target = document.querySelector('.js-lottie')
    let animData = target.getAttribute('data-json')
    let lottieObj = lottie.loadAnimation({
      container: target,
      renderer: 'svg',
      loop: true,
      path: animData,
    })
    lottieObj.setSpeed(0.5)
  }
}
