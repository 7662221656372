export class TextSplit {
  constructor() {
    const targetArr = document.querySelectorAll('.js-split')
    if (targetArr.length === 0) return
    targetArr.forEach((e) => {
      const text = e.textContent
      e.innerHTML = ''
      text.split('').forEach((c) => {
        if (c === ' ') {
          e.innerHTML += '<span>&nbsp;</span>'
        } else {
          e.innerHTML += '<span>' + c + '</span>'
        }
      })
    })
  }
}
