import { gsap } from 'gsap'

export class AccordionExpand {
  constructor() {
    const acoArr = document.querySelectorAll('.js-aco-expand')

    if (!acoArr) return

    let closedHeight = '10em' // 閉じた状態の高さ
    const duration = 0.5

    acoArr.forEach((aco) => {
      const acoTrg = aco.querySelector('.js-aco-expand_trg')
      if (!acoTrg) return

      const originalTrgText = acoTrg.querySelector('.in').textContent
      const acoElm = aco.querySelector('.js-aco-expand_elm')
      const acoWrap = document.createElement('div')
      acoWrap.classList.add('js-aco-expand_wrap')
      acoElm.parentNode.insertBefore(acoWrap, acoElm)
      acoWrap.appendChild(acoElm)

      let originalHeight
      const updateHeight = () => {
        originalHeight = acoElm.clientHeight
      }

      const dataHeight = aco.getAttribute('data-height')
      if (dataHeight) {
        closedHeight = dataHeight
      }

      const animation = (e, type) => {
        if (type == 'open') {
          gsap.to(e, duration, { height: originalHeight })
        } else {
          gsap.to(e, duration, { height: closedHeight })
        }
      }

      animation(acoWrap, 'close')

      acoTrg.addEventListener('click', () => {
        aco.classList.toggle('is-open')
        if (aco.classList.contains('is-open')) {
          acoTrg.querySelector('.in').textContent = '閉じる'
          animation(acoWrap, 'open')
        } else {
          animation(acoWrap, 'close')
          acoTrg.querySelector('.in').textContent = originalTrgText
        }
      })

      // ウィンドウのリサイズイベントで高さを更新
      window.addEventListener('resize', () => {
        updateHeight()
        console.log(originalHeight)
      })

      // 初期化時に高さを更新
      updateHeight()
      console.log(originalHeight)
    })
  }
}
