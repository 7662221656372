import Swiper from 'swiper'
import { Navigation, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

export class SchoolVoiceSlider {
  constructor() {
    this.slider = document.querySelector('.js-school_voice_slider')
    if (!this.slider) return

    this.controller = document.querySelector('.p-school-single_voice .c-slider_controller01')

    let swiper

    const initSwiper = () => {
      swiper = new Swiper(this.slider, {
        modules: [Navigation, Scrollbar],
        slidesPerView: 1,
        speed: 500,
        spaceBetween: 20,
        navigation: {
          nextEl: `.p-school-single_voice .js-slider_button.-next`,
          prevEl: `.p-school-single_voice .js-slider_button.-prev`,
        },
        scrollbar: {
          el: '.p-school-single_voice .js-slider_scrollbar',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            spaceBetween: 40,
          },
          1300: {
            slidesPerView: 2.3,
          },
        },
      })

      // スライドさせない時にコントローラを非表示に
      if (swiper.slides.length <= 1) {
        this.controller.style.display = 'none'
      }
    }

    initSwiper()
  }
}
