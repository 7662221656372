import gsap, { Power2 } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

/**
 * アンカーリンクをなめらかにスクロール。
 * ページ読み込み時にハッシュがついている時、そのIDのタグがあれば同じくスクロール
 */
export class Smooth {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      let headerHeight = document.getElementById('js-header').offsetHeight
      window.addEventListener('load', () => {
        const hash = location.hash
        if (hash) {
          const hashTarget = document.querySelector(hash)
          if (hashTarget !== null) {
            gsap.to(window, {
              duration: 0,
              ease: Power2.easeInOut,
              scrollTo: {
                y: hashTarget,
                offsetY: headerHeight + 80,
              },
            })
          }
        }

        this.smoothScrollTriggers = [...document.querySelectorAll('a[href^="#"]')]

        if (this.smoothScrollTriggers.length === 0) return

        this.smoothScrollTriggers.forEach((trigger) => {
          trigger.addEventListener('click', (e) => {
            e.preventDefault()
            gsap.to(window, {
              duration: 0.5,
              ease: Power2.easeInOut,
              scrollTo: {
                y: trigger.getAttribute('href'),
                offsetY: headerHeight + 80,
              },
            })
          })
        })
      })
    })
  }
}
