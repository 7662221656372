import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class AnchorSidebar {
  constructor() {
    const anchor = document.querySelector('.js-anchor')
    if (!anchor) return

    const secArr = document.querySelectorAll('.js-anchor_section')
    if (!secArr) return

    let isAnimation = false

    let html = ''

    secArr.forEach((sec, i) => {
      const dataTitle = sec.dataset.title
      const dataSub = sec.dataset.sub
      const id = 'section0' + (i + 1)

      sec.setAttribute('id', id)

      if (dataSub) {
        html += `<a href="#${id}" class="js-anchor_link"><p>${dataSub}</p>${dataTitle}</a>`
      } else {
        html += `<a href="#${id}" class="js-anchor_link">${dataTitle}</a>`
      }
    })
    anchor.innerHTML += html

    const anchorLinks = document.querySelectorAll('.js-anchor_link')

    // 目次のスタイルを変える関数
    const activateAnchor = (target) => {
      const currentActive = document.querySelector('.js-anchor_link.is-current')
      if (currentActive !== null) {
        currentActive.classList.remove('is-current')
      }
      const newActive = document.querySelector(`a[href='#${target.id}']`)
      newActive.classList.add('is-current')
    }

    const activateAnchorItem = (item) => {
      item.classList.add('is-current')
      anchorLinks.forEach((elm) => {
        if (elm !== item) elm.classList.remove('is-current')
      })
    }

    anchorLinks.forEach((item) => {
      item.addEventListener('click', (event) => {
        const target = event.currentTarget
        isAnimation = true
        activateAnchorItem(target)
        setTimeout(() => {
          isAnimation = false
        }, 500)
      })
    })

    secArr.forEach((sec) => {
      gsap.to(sec, {
        scrollTrigger: {
          trigger: sec,
          start: 'top center',
          end: 'bottom center',
          markers: false,
          onEnter: () => {
            if (isAnimation) return
            activateAnchor(sec)
          },
          onEnterBack: () => {
            if (isAnimation) return
            activateAnchor(sec)
          },
        },
      })
    })
  }
}
