import { gsap } from 'gsap'

export class Tab {
  constructor() {
    const tabArr = document.querySelectorAll('.js-tab')
    if (!tabArr) return

    const duration = 0.3

    const animation = (elm, type) => {
      if (type == 'open') {
        gsap.to(elm, { duration, autoAlpha: 1, display: 'block', delay: duration })
      } else {
        gsap.to(elm, { duration, autoAlpha: 0, display: 'none' })
      }
    }

    tabArr.forEach((tab) => {
      const tabTrgArr = tab.querySelectorAll('.js-tab_trg')
      const tabContentArr = tab.querySelectorAll('.js-tab_content')

      // 初期表示の設定（is-actクラスが付いているタブを表示）
      tabTrgArr.forEach((trg, i) => {
        if (trg.classList.contains('is-act')) {
          animation(tabContentArr[i], 'open')
        } else {
          animation(tabContentArr[i], 'close')
        }
      })

      tabTrgArr.forEach((trg, i) => {
        trg.addEventListener('click', (event) => {
          event.preventDefault()
          // すべてのコンテンツを非表示にする
          tabContentArr.forEach((content) => {
            animation(content, 'close')
          })

          // クリックされたタブにis-actクラスを付ける
          tabTrgArr.forEach((trg) => trg.classList.remove('is-act'))
          trg.classList.add('is-act')

          // 対応するコンテンツを表示する
          animation(tabContentArr[i], 'open')
        })
      })
    })
  }
}
