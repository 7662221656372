import { gsap } from 'gsap'

export class Accordion {
  constructor() {
    const acoArr = document.querySelectorAll('.js-aco')
    if (!acoArr) return

    const duration = 0.5

    const animation = (elm, type) => {
      if (type == 'open') {
        gsap.to(elm, duration, { height: 'auto' })
      } else {
        gsap.to(elm, duration, { height: 0 })
      }
    }

    acoArr.forEach((target) => {
      const acoTrg = target.querySelector(':scope > .js-aco_trg')
      const acoElm = target.querySelector(':scope > .js-aco_elm')

      // .js-aco_elmを囲む要素を作成し、その中に移動させる
      const acoWrap = document.createElement('div')
      acoWrap.classList.add('js-aco_wrap')
      acoElm.parentNode.insertBefore(acoWrap, acoElm)
      acoWrap.appendChild(acoElm)

      if (target.classList.contains('is-open')) {
        animation(acoWrap, 'open')
      }

      acoTrg.addEventListener('click', () => {
        target.classList.toggle('is-open')

        if (target.classList.contains('is-open')) {
          animation(acoWrap, 'open')
        } else {
          animation(acoWrap, 'close')
        }
      })
    })
  }
}
